<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "payslip-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "draft":
          return "Entwurf";
        case "paymentToBeInstructed":
          return "Zahlung anzuweisen";
        case "waitingOnPayment":
          return "Warten auf Zahlung";
        case "paid":
          return "Ausgezahlt";
        case "archived":
          return "Archiviert";
        default:
          return "Fehler";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "draft":
          return "brown";
        case "paymentToBeInstructed":
        case "waitingOnPayment":
          return "orange";
        case "paid":
          return "green";
        case "archived":
          return "blue-grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
