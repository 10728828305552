<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="payslipsOutput"
      item-key="meta.id"
      multi-sort
      sort-by="period.start.timestamp"
      :sort-desc="true"
      :search="search"
      :items-per-page="15"
      :footer-props="footerProps"
      group-by="batch.id"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Abrechnungszettel vorhanden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row dense>
              <v-col v-if="showAllPayslips" cols="12" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Suchen"
                  :disabled="payslipsPersonFilter === 'own'"
                  hide-details="auto"
                  clearable
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="showAllPayslips"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="payslipsPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <!-- <v-col
                v-if="showAllPayslips"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="payslipsStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="inProgress"
                    class="transparent-background font-weight-medium mr-2"
                    >In Bearbeitung</v-chip
                  >
                  <v-chip
                    label
                    value="toBeReviewed"
                    class="transparent-background font-weight-medium mr-2"
                    >Zu prüfen</v-chip
                  >
                </v-chip-group>
              </v-col> -->
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle" class="mr-1"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ items[0].batch.title }}
        </th>
      </template>
      <template v-slot:[`item.period.start.timestamp`]="{ item }">
        {{ item.period.start.formattedTimestamp }}
      </template>
      <template v-slot:[`item.period.end.timestamp`]="{ item }">
        {{ item.period.end.formattedTimestamp }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip outlined small label>
          {{ getPayrollTypeText(item.type) }}
        </v-chip>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <payslip-status-chip :status="item.status" small> </payslip-status-chip>
      </template>
      <template v-slot:[`item.summary.totalCompensation.amount`]="{ item }">
        <pre
          >{{ item.summary.totalCompensation.amount }} {{
            item.summary.totalCompensation.currency
          }}</pre
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import PayslipStatusChip from "@/components/accounting/payslips/PayslipStatusChip.vue";
import { ACCOUNTING_PAYSLIPS_read_all } from "@/data/permission-types.js";
export default {
  name: "payslips-data-table",
  props: {
    payslips: {
      type: Array,
      required: true,
    },
  },
  components: {
    PayslipStatusChip,
  },
  data() {
    return {
      search: "",
      payslipsPersonFilter: "own",
      payslipsStateFilter: "all",
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    showAllPayslips() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ACCOUNTING_PAYSLIPS_read_all}`
      );
    },
    payslipsOutput() {
      return this.payslips.filter((leave) => {
        if (this.payslipsPersonFilter === "own") {
          return leave.user.uid === this.currentUserUid;
        }
        return true;
      });
    },
    headers() {
      const headersArray = [];

      if (this.payslipsPersonFilter === "all") {
        headersArray.push({
          text: "Vorname",
          value: "user.firstName",
        });
        headersArray.push({
          text: "Nachname",
          value: "user.lastName",
        });
      }

      headersArray.push(
        // { text: "Abrechnungsstapel", value: "batch.title" },
        { text: "Typ", value: "type" },
        { text: "Abrechnung von", value: "period.start.timestamp" },
        { text: "Abrechnung bis", value: "period.end.timestamp" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.payslipsStateFilter) return true;
            if (this.payslipsStateFilter === "all") return true;
            return value === this.payslipsStateFilter;
          },
          divider: true,
        },
        {
          text: "Aufwand in H",
          value: "summary.totalBillableTime.hours",
          divider: true,
        },
        { text: "Aufwand", value: "summary.totalCompensation.amount" }
      );

      return headersArray.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "accounting-payslips-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    getPayrollTypeText(type) {
      switch (type) {
        case "regular":
          return "Regulärer Turnus";
        case "special":
          return "Sonderzahlung";
        default:
          return "Unbekannt";
      }
    },
  },
};
</script>
